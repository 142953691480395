import { sidebarsVar } from '@/graphql/cache';

export default function showSidebar(id, props) {
  const sidebars = sidebarsVar();

  return sidebarsVar(
    sidebars.concat({
      id,
      props,
    }),
  );
}

import { useReactiveVar } from '@apollo/client';
import { sidebarsVar } from '@/graphql/cache';
import showSidebar from '../utils/showSidebar';

/**
 * @returns {{ data: Array, toggle: Function, show: Function, replace: Function, close: Function, closeAll: Function } }}
 */
export default function useSidebars() {
  const sidebars = useReactiveVar(sidebarsVar);

  function toggleSidebar(id, props) {
    if (!id) return [];

    if (sidebars.some((s) => s.id === id)) {
      return closeSidebar(id);
    }

    return showSidebar(id, props);
  }

  function closeSidebar(id) {
    return sidebarsVar(sidebars.filter((s) => s.id !== id));
  }

  function closeAllSidebars() {
    return sidebarsVar([]);
  }

  /**
   * @param {object} replacementSidebar
   * @param {string} replacementSidebar.id
   * @param {object} replacementSidebar.props
   * @param {object} replacementSidebar.sidebarProps
   * @param {string|null} sourceSidebarId
   * @returns {Array}
   */
  function replaceSidebar(replacementSidebar, sourceSidebarId = null) {
    const sourceSidebarIndex = Math.max(
      0,
      sidebars.findIndex((s) => s.id === sourceSidebarId),
    );
    const newSidebars = [...sidebars];
    newSidebars[sourceSidebarIndex] = replacementSidebar;

    return sidebarsVar(newSidebars);
  }

  return {
    data: sidebars,
    toggle: toggleSidebar,
    show: showSidebar,
    replace: replaceSidebar,
    close: closeSidebar,
    closeAll: closeAllSidebars,
  };
}
